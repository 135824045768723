<template>
    <div>
        <mds-layout-grid>
            <!-- workflow health -->
                        <mds-row>
                            <mds-col :cols="12" v-if="
                                permissions.includes('home-worflow-list') ||
                                permissions.includes('*')
                            ">
                                <mds-section
                                    id="workflowHealth"
                                    class="workflow-health"
                                    bold
                                    border="primary"
                                    title="Workflow Health"
                                    borderPosition="bottom"
                                >
                                    <mds-list-group with-icon class="event-list">
                                        <mds-list-group-item
                                            class="health-list-item"
                                            v-for="workflow in getworkflowHealthList"
                                            :key="workflow.objectId"
                                        >
                                            <template>
                                                <mds-row>
                                                    <mds-col :cols="1">
                                                        <span
                                                            class="
                                                                health-status-indicator
                                                            "
                                                            :style="{
                                                                height: '10px',
                                                                width: '10px',
                                                                'background-color':
                                                                    colorCode(
                                                                        workflow.health
                                                                    ),
                                                                'border-radius':
                                                                    '50%',
                                                                display:
                                                                    'inline-block',
                                                            }"
                                                        >
                                                        </span>
                                                    </mds-col>
                                                    <mds-col
                                                        class="
                                                            sub-heading-title
                                                        "
                                                    >
                                                        <span @click="getRouteDetails(workflow)">{{
                                                            workflow.objectLabel
                                                        }}</span>
                                                    </mds-col>
                                                </mds-row>
                                            </template>
                                        </mds-list-group-item>
                                    </mds-list-group>
                                    <mds-empty-state
                                    v-if="getworkflowHealthList == undefined"
                                    class="no-results-message"
                                    size="medium"
                                    title="No data found"
                                    message=""
                                ></mds-empty-state>
                                </mds-section>
                            </mds-col>
                        </mds-row>
        </mds-layout-grid>
        
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsSection from "@mds/section";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import MdsEmptyState from "@mds/empty-state";
    export default {
        name:"homeWorkflowHealth",
        components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsSection,
        MdsListGroup,
        MdsListGroupItem,
        MdsEmptyState,
    },

    data(){
        return{
        }
    },

        props:{
            getworkflowHealthList: {
            type: Array,
        },
        },

        async created(){
        
        },
        async mounted(){

        },

        computed:{
            permissions() {
            return this.$store.state.auth.permissions;
        },
        },
        methods:{
        // colour code for health
        colorCode(Health_status) {
            let healthstatus = Health_status.toLowerCase();
            if (healthstatus === "good" || healthstatus === "on track") {
                return "#00AF41";
            } else if (healthstatus === "poor" || healthstatus === "overdue") {
                return "#FF0000";
            } else if (healthstatus === "fair" || healthstatus === "due soon") {
                return "#F5C400";
            } else {
                return "#E5E5E5";
            }
        },

        getRouteDetails(array){
                this.$router.push({
                    name: "Assigned To You",
                    params: {
                        workflowObject: array,
                    },
                });
            }

        },


    }
</script>

<style lang="scss" scoped>

.workflow-health {
    margin-top: 16px;
    max-height: 450px;
    min-height: 30px;
    padding: 0px;
}

.event-list{
    margin-top: 8px;
    max-height: 425px;
    overflow-y: auto;
}

.health-list-item ::v-deep {
    padding-top: 12px;
    padding-bottom: 12px;
    .mds-list-group__link___VueMDS3Demo {
        padding-left: 0px;
    }
}

.sub-heading-title {
    padding-left: 4px;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}
</style>