<template>
    <div>
        <homeTask
            :tasks_headers="tasks_headers"
            :tasks_rows="tasks_rows"
            :itemsAssigned="itemsAssigned"
            @handleSortEvent="handleSortEvent"
        ></homeTask>
        <spinLoaderComponent v-if="showLoader"></spinLoaderComponent>
    </div>
</template>

<script>
import homeTask from "../components/HomeTask.vue";
import { mapActions, mapGetters } from "vuex";
import spinLoaderComponent from "../../../ui_component/spinLoaderComponent.vue";
export default {
    name: "homeTaskContainer",
    components: {
        homeTask,
        spinLoaderComponent,
    },
    data() {
        return {
            showLoader: true,
            tasks_headers: [
                {
                    text: "Workflow",
                    fieldName: "Workflow",
                    width: "18%",
                    sortable: true,
                },
                {
                    text: "Step",
                    fieldName: "Step",
                    width: "8%",
                    sortable: true,
                },
                {
                    text: "Entity",
                    fieldName: "Entity",
                    width: "11%",
                    sortable: true,
                },
                {
                    text: "Updated (UTC)",
                    fieldName: "UpdatedAt",
                    sortable: true,
                    sorted: -1,
                    width: "10%",
                },
                {
                    text: "Health",
                    fieldName: "Health",
                    sortable: true,
                    width: "4%",
                },
            ],
            tasks_rows: [],
            sortOrderColumnName: "UpdatedAt",
            sortColIndex: 3,
            sortOrder: -1,
            sortOrderIsAscending: false,
            itemsAssigned: false,
        };
    },

    async created() {},
    async mounted() {
        if (
            this.permissions.includes("home-task-list") ||
            this.permissions.includes("*")
        ) {
            await this.getTaskPanelList();
        }
        this.showLoader = false;
    },

    computed: {
        ...mapGetters("assignedToYou", ["getAssignedItems"]),

        permissions() {
            return this.$store.state.auth.permissions;
        },
    },
    methods: {
        ...mapActions("assignedToYou", ["setAssignedItems"]),

        async handleSortEvent(colIndex, colName) {
            this.tasks_rows = [];
            this.executeSortingLogicForMdsTable(colIndex);
            this.sortOrderColumnName = colName;
            this.sortOrder == 1
                ? (this.sortOrderIsAscending = true)
                : (this.sortOrderIsAscending = false);
            await this.getTaskPanelList();
        },

        executeSortingLogicForMdsTable(colIndex) {
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                this.$set(this.tasks_headers[this.sortColIndex], "sorted", 0);
                this.sortOrder = -1;
            }

            this.sortColIndex = colIndex;
            this.$set(this.tasks_headers[colIndex], "sorted", this.sortOrder);
        },

        //creating JSON object in desired format from the workflow-open-tasks api.
        async getTaskPanelList() {
            this.showLoader = true;
            let data = {
                Page: 1,
                PageSize: 10,
                orderByColumnName: this.sortOrderColumnName,
                isAscending: this.sortOrderIsAscending,
                PaginationSearchParameters: [],
            };
            await this.setAssignedItems(data);
            let response = this.getAssignedItems.data;
            let rowData = {
                rows: [],
            };
            if (response != undefined) {
                if (this.getAssignedItems.isError !== true) {
                    if (response.list.length > 0) {
                        response.list.forEach((item) => {
                            let data = JSON.parse(item.objectdetail);
                            let modifiedObject = {};
                            modifiedObject.id = item.id;
                            modifiedObject.Workflow = data.Workflow;
                            modifiedObject.Step = data.Step;
                            modifiedObject.Entity = data.Entity;
                            modifiedObject.Due = item.duedate;
                            modifiedObject.UpdatedAt = data.Updated.replace(
                                /"/g,
                                ""
                            );
                            modifiedObject.UpdatedBy = data.UpdatedBy;
                            modifiedObject.Health = item.health;
                            for (const item in modifiedObject) {
                                modifiedObject[item] =
                                    modifiedObject[item] === "" ||
                                    modifiedObject[item] === null
                                        ? "\u2014"
                                        : modifiedObject[item];
                            }

                            modifiedObject.editmode = data.editmode;
                            modifiedObject.editurl = data.editurl;
                            modifiedObject.entityid = data.entityid;

                            rowData.rows.push(modifiedObject);
                        });
                    } else if (response.list.length === 0) {
                        rowData.rows = undefined;
                    }
                }
                this.itemsAssigned =
                    response && response.list.length > 0 ? true : false;
            } else {
                rowData.rows = undefined;
            }
            this.tasks_rows = rowData.rows;
            this.showLoader = false;
        },
    },
};
</script>

<style scoped>
</style>