<template>
    <div class="spin_loader">
        <!-- Medium (Default) -->
<mds-loader aria-label="Medium Loader"></mds-loader>
    </div>
</template>

<script>
import MdsLoader from '@mds/loader'
    export default {
        components:{
            MdsLoader,
        }
        
    }
</script>

<style lang="scss" scoped>

.spin_loader{
    height: 400px;
}
</style>