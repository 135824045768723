var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('mds-layout-grid',[_c('mds-row',[(
                            _vm.permissions.includes('home-worflow-list') ||
                            _vm.permissions.includes('*')
                        )?_c('mds-col',{attrs:{"cols":12}},[_c('mds-section',{staticClass:"workflow-health",attrs:{"id":"workflowHealth","bold":"","border":"primary","title":"Workflow Health","borderPosition":"bottom"}},[_c('mds-list-group',{staticClass:"event-list",attrs:{"with-icon":""}},_vm._l((_vm.getworkflowHealthList),function(workflow){return _c('mds-list-group-item',{key:workflow.objectId,staticClass:"health-list-item"},[[_c('mds-row',[_c('mds-col',{attrs:{"cols":1}},[_c('span',{staticClass:"\n                                                            health-status-indicator\n                                                        ",style:({
                                                            height: '10px',
                                                            width: '10px',
                                                            'background-color':
                                                                _vm.colorCode(
                                                                    workflow.health
                                                                ),
                                                            'border-radius':
                                                                '50%',
                                                            display:
                                                                'inline-block',
                                                        })})]),_c('mds-col',{staticClass:"\n                                                        sub-heading-title\n                                                    "},[_c('span',{on:{"click":function($event){return _vm.getRouteDetails(workflow)}}},[_vm._v(_vm._s(workflow.objectLabel))])])],1)]],2)}),1),(_vm.getworkflowHealthList == undefined)?_c('mds-empty-state',{staticClass:"no-results-message",attrs:{"size":"medium","title":"No data found","message":""}}):_vm._e()],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }