<template>
    <div>
        <mds-layout-grid>
            <!-- Task -->
            <mds-row
                class="task"
                v-if="
                    permissions.includes('home-task-list') ||
                    permissions.includes('*')
                "
            >
                <mds-section
                    bold
                    border="primary"
                    title="Tasks"
                    borderPosition="bottom"
                >
                    <template
                        v-slot:mds-section-actions
                        v-if="itemsAssigned === true"
                    >
                        <button-component
                            :onClick="navigateToAssigned"
                            buttonName="View All"
                            buttonVariation="secondary"
                        >
                        </button-component>
                    </template>
                    <!-- table content -->
                    <mds-table row-hover show-sortable class="task-table">
                        <mds-thead>
                            <mds-th
                                :style="{ width: header.width }"
                                v-for="(header, index) in tasks_headers"
                                :key="index"
                                :sortable="header.sortable"
                                :sorted="header.sorted"
                                @mds-th-sort-changed="
                                    handleSortEvent(
                                        index,
                                        header.fieldName,
                                        $event
                                    )
                                "
                            >
                                {{ header.text }}
                            </mds-th>
                        </mds-thead>
                        <mds-tbody>
                            <mds-tr
                                v-for="(row, index) in tasks_rows"
                                :key="index"
                            >
                                <mds-td
                                    v-for="(header, i) in tasks_headers"
                                    :key="i"
                                    :right-aligned="header.align === 'right'"
                                >
                                    <template
                                        v-if="header.fieldName === 'Workflow'"
                                    >
                                       <span
                                            class="no-highlight"
                                            v-if="row['editmode'] === 'true'"
                                            @click="
                                                redirectToAddInvestmentScreen(
                                                    row
                                                )
                                            "
                                        >
                                            {{ row[header.fieldName] }}
                                        </span>

                                        <span
                                            class="no-highlight"
                                            v-if="row['editmode'] === 'false'"
                                            @click="
                                                redirectToApproveScreen(row)
                                            "
                                        >
                                            {{ row[header.fieldName] }}
                                        </span>
                                    </template>
                                    <template
                                        v-if="header.fieldName === 'Health'"
                                    >
                                        <span
                                            class="health-status"
                                            :style="{
                                                height: '10px',
                                                width: '10px',
                                                'background-color': colorCode(
                                                    row[header.fieldName]
                                                ),
                                                'border-radius': '50%',
                                                display: 'inline-block',
                                                'margin-right': '30%',
                                            }"
                                        >
                                        </span>
                                    </template>
                                    <template
                                        v-if="header.fieldName === 'UpdatedAt'"
                                    >
                                        {{ convertDate(row[header.fieldName]) }}
                                    </template>
                                    <template
                                        v-if="
                                            header.fieldName === 'Step' ||
                                            header.fieldName === 'Entity' ||
                                            header.fieldName === 'UpdatedBy'
                                        "
                                    >
                                        {{ row[header.fieldName] }}
                                    </template>
                                </mds-td>
                            </mds-tr>
                        </mds-tbody>
                    </mds-table>
                    <mds-empty-state
                        v-if="tasks_rows == undefined"
                        class="no-results-message"
                        size="medium"
                        title="No data found"
                        message=""
                    ></mds-empty-state>
                </mds-section>
            </mds-row>
        </mds-layout-grid>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import MdsSection from "@mds/section";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
} from "@mds/data-table-4.0.2";
import ButtonComponent from "../../../ui_component/ButtonComponent.vue";
import { dateFormatterMixin } from "../../../../mixins/date.js";
import MdsEmptyState from "@mds/empty-state";

export default {
    name: "homeTask",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsSection,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTbody,
        MdsTr,
        MdsTd,
        ButtonComponent,
        MdsEmptyState,
    },
    mixins: [dateFormatterMixin],
    data() {
        return {};
    },

    props: {
        itemsAssigned: {
            type: Boolean,
            default: false,
        },
        tasks_rows: {
            type: Array,
        },
        tasks_headers: {
            type: Array,
            default: () => [],
        },
    },

    async created() {},
    async mounted() {},

    computed: {
        permissions() {
            return this.$store.state.auth.permissions;
        },
    },
    methods: {
        // after clicking view all redirect to assigned to you page
        navigateToAssigned() {
            if (this.$route.path != "/tasks/assigned-to-you") {
                this.$router.push("/tasks/assigned-to-you").catch(() => {});
            }
        },

        handleSortEvent(colIndex, colName) {
            this.$emit("handleSortEvent", colIndex, colName);
        },

        // create route according to url
        getRouteDetails(url) {
            const routeDetails = this.$router.resolve({
                path: url,
            });
            return routeDetails.resolved.name;
        },

        // colour code for health
        colorCode(Health_status) {
            let healthstatus = Health_status.toLowerCase();
            if (healthstatus === "good" || healthstatus === "on track") {
                return "#00AF41";
            } else if (healthstatus === "poor" || healthstatus === "overdue") {
                return "#FF0000";
            } else if (healthstatus === "fair" || healthstatus === "due soon") {
                return "#F5C400";
            } else {
                return "#E5E5E5";
            }
        },

        redirectToApproveScreen(value) {
            this.$router.push(`/approve?id=${value.id}`);
        },
        redirectToAddInvestmentScreen(value) {
            this.$router.push(
                `/data-management/add-investment?entityid=${
                    value.entityid
                }&taskId=${value.id}&isEditable=${true}`
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.task-table {
    margin-top: 28px;
}

.no-highlight {
    color: black;
    cursor: pointer;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}
</style>