<template>
    <div id="dashboard">
        <div class="dashboard-outline">
            <mds-layout-grid>
                <mds-row>
                    <mds-col class="welcome-heading-container" :cols="11">
                        <h2 class="welcome-heading">
                            Welcome back, {{ userName }}.
                        </h2>
                    </mds-col>
                </mds-row>
                <!-- updates -->
                <mds-row>
                    <mds-col class="left-block" :cols="6" :cols-at-xl="7" >
                        <!-- Task -->
                        <homeTaskContainer></homeTaskContainer>
                        
                    </mds-col>

                    <mds-col class="right-block" :cols="2" :cols-at-xl="4">
                        <!-- workflow health -->
                        <homeWorkflowHealthContainer></homeWorkflowHealthContainer>
                        
                        </mds-col>
                </mds-row>
            </mds-layout-grid>
        </div>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import homeTaskContainer from "../container/HomeTaskContainer.vue";
import homeWorkflowHealthContainer from "../container/HomeWorkflowHealthContainer.vue";

export default {
    name: "dashboard",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        homeTaskContainer,
        homeWorkflowHealthContainer,
    },


    data() {
        return {
            userName: "",

        };
    },

    // name should be displayed whose  credentials entered for login
    created() {
        if (sessionStorage.getItem("permissions")) {
            var permissions = JSON.parse(sessionStorage.getItem("permissions"));
            let arr = permissions.userEmail;
            let res = arr.split(".");
            let firstName = res[0].charAt(0).toUpperCase() + res[0].slice(1);
            this.userName = firstName;
        }
    },

    async mounted() {
    },
    computed: {
    },
    methods: {
       
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.dashboard-outline {
    margin: 16px;
}
.welcome-heading {
    margin-top: 16px;
    color: #1e1e1e;
    font-size: 32px;
    letter-spacing: 0;
    @include mds-level-2-heading($bold: false);
}
.left-block {
    height: 100%;
    width: 780px;
    margin-left: 16px;
    margin-top: 16px;
}
.middle-block {
    height: 100%;
    width: 315px;
    margin-left: 32px;
}

.right-block {
    height: 100%;
    width: 197px;
    margin-left: 32px;
}

#dashboard::v-deep
    .mds-list-group--with-icon___VueMDS3Demo
    .mds-list-group__item-text___VueMDS3Demo {
    padding-left: 0px;
}
#dashboard::v-deep
    .mds-section___VueMDS3Demo
    .mds-section__header-container___VueMDS3Demo {
    display: flex;
    margin-bottom: 0px;
}

::v-deep
    .mds-data-table--show-sortable___VueMDS3Demo
    .mds-data-table__header-cell--sort___VueMDS3Demo
    .mds-data-table__header-cell-inner___VueMDS3Demo {
    border-bottom: none;
}
</style>
