var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('mds-layout-grid',[(
                _vm.permissions.includes('home-task-list') ||
                _vm.permissions.includes('*')
            )?_c('mds-row',{staticClass:"task"},[_c('mds-section',{attrs:{"bold":"","border":"primary","title":"Tasks","borderPosition":"bottom"},scopedSlots:_vm._u([(_vm.itemsAssigned === true)?{key:"mds-section-actions",fn:function(){return [_c('button-component',{attrs:{"onClick":_vm.navigateToAssigned,"buttonName":"View All","buttonVariation":"secondary"}})]},proxy:true}:null],null,true)},[_c('mds-table',{staticClass:"task-table",attrs:{"row-hover":"","show-sortable":""}},[_c('mds-thead',_vm._l((_vm.tasks_headers),function(header,index){return _c('mds-th',{key:index,style:({ width: header.width }),attrs:{"sortable":header.sortable,"sorted":header.sorted},on:{"mds-th-sort-changed":function($event){return _vm.handleSortEvent(
                                    index,
                                    header.fieldName,
                                    $event
                                )}}},[_vm._v(" "+_vm._s(header.text)+" ")])}),1),_c('mds-tbody',_vm._l((_vm.tasks_rows),function(row,index){return _c('mds-tr',{key:index},_vm._l((_vm.tasks_headers),function(header,i){return _c('mds-td',{key:i,attrs:{"right-aligned":header.align === 'right'}},[(header.fieldName === 'Workflow')?[(row['editmode'] === 'true')?_c('span',{staticClass:"no-highlight",on:{"click":function($event){return _vm.redirectToAddInvestmentScreen(
                                                row
                                            )}}},[_vm._v(" "+_vm._s(row[header.fieldName])+" ")]):_vm._e(),(row['editmode'] === 'false')?_c('span',{staticClass:"no-highlight",on:{"click":function($event){return _vm.redirectToApproveScreen(row)}}},[_vm._v(" "+_vm._s(row[header.fieldName])+" ")]):_vm._e()]:_vm._e(),(header.fieldName === 'Health')?[_c('span',{staticClass:"health-status",style:({
                                            height: '10px',
                                            width: '10px',
                                            'background-color': _vm.colorCode(
                                                row[header.fieldName]
                                            ),
                                            'border-radius': '50%',
                                            display: 'inline-block',
                                            'margin-right': '30%',
                                        })})]:_vm._e(),(header.fieldName === 'UpdatedAt')?[_vm._v(" "+_vm._s(_vm.convertDate(row[header.fieldName]))+" ")]:_vm._e(),(
                                        header.fieldName === 'Step' ||
                                        header.fieldName === 'Entity' ||
                                        header.fieldName === 'UpdatedBy'
                                    )?[_vm._v(" "+_vm._s(row[header.fieldName])+" ")]:_vm._e()],2)}),1)}),1)],1),(_vm.tasks_rows == undefined)?_c('mds-empty-state',{staticClass:"no-results-message",attrs:{"size":"medium","title":"No data found","message":""}}):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }