<template>
    <div>
        <HomeWorkflowHealth
        :getworkflowHealthList="getworkflowHealthList"
        ></HomeWorkflowHealth>
        <spinLoaderComponent v-if="showLoader"></spinLoaderComponent>
    </div>
</template>

<script>
import HomeWorkflowHealth from '../components/HomeWorkflowHealth.vue';
import spinLoaderComponent from "../../../ui_component/spinLoaderComponent.vue";
import store from "@/store";
import {  mapState, mapGetters } from "vuex";
import get from "lodash/get";
import { DASHBOARD_ACTIONS } from "@/store/modules/dashboard/contants.js";

    export default {
        beforeCreate() {
        this.$store = store;
    },
        components:{
            HomeWorkflowHealth,
            spinLoaderComponent,
        },
        name:"homeWorkflowHealthContainer",
        
    data(){
        return{
            showLoader:true,
            getworkflowHealthList:[],
        }
    },

        async created(){
        
        },
        async mounted(){
             if (
            this.permissions.includes("home-worflow-list") ||
            this.permissions.includes("*")
        ) {
            await this.cachedDispatch(DASHBOARD_ACTIONS.FETCH_WORKFLOW_HEALTH_LIST);
            this.WorkflowHealthList();
        }
                this.showLoader = false;
        },

        computed:{
        ...mapGetters(["cachedDispatch"]),
            permissions() {
            return this.$store.state.auth.permissions;
            },
            ...mapState({
            workflowHealthList(state) {
                return get(
                    state,
                    "dashboard.workflowHealth.workflowHealth",
                    []
                );
            },
        }),
        
        },
        methods:{
            WorkflowHealthList(){
                if(this.workflowHealthList.length === 0){
                    this.getworkflowHealthList = undefined;
                }
                else{
                    this.getworkflowHealthList = this.workflowHealthList;
                }
            }
        },

    }
</script>

<style scoped>

</style>